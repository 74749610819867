<template>
  <div class="ruleBox">
    <DetailBox title="规则">
      <TextBox :font-size="30" color="title"> 第一期奖励规则 </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1">
        一期奖励达标用户每天的奖励收益=当前奖励总额度/当前达标总用户
      </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1"> 注： </TextBox>
      <TextBox :font-size="26" color="intro1"> v1用户享受v1奖励收益； </TextBox>
      <TextBox :font-size="26" color="intro1"> v2用户享受v1、v2奖励收益； </TextBox>
      <TextBox :font-size="26" color="intro1"> v3用户享受v1、v2、v3奖励收益 </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1"> 奖励发放时间在每天下午12:00-13:00各位用户请知晓。 </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="30" color="title"> 第二期奖励规则 </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1">
        二期奖励达标用户每天的奖励收益=当前奖励总额度/当前达标总用户
      </TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1">注：</TextBox>
      <TextBox :font-size="26" color="intro1">v1用户享受v1奖励收益；</TextBox>
      <TextBox :font-size="26" color="intro1">v2用户享受v1、v2奖励收益；</TextBox>
      <TextBox :font-size="26" color="intro1">v3用户享受v1、v2、v3奖励收益</TextBox>
      <Space :height="30"></Space>
      <TextBox :font-size="26" color="intro1"> 奖励发放时间在每天下午12:00-13:00各位用户请知晓。 </TextBox>
    </DetailBox>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
